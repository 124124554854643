<template>
  <v-form>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("reset.password.page.title") }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-row>
        <template v-if="bValidRoute">
          <v-col cols="12">
            <form-field-password
              required
              rules="required|max:100|min:8"
              vid="password"
              label="password"
              v-model="sPassword"
              @save="onResetPassword"
            />
          </v-col>
          <v-col cols="12">
            <form-field-password
              required
              rules="required|confirmed:password"
              vid="password_confirmation"
              label="password.confirmation"
              v-model="sConfirmationPassword"
              @save="onResetPassword"
            />
          </v-col>
        </template>
        <v-col cols="12" v-else>
          <v-alert type="error">{{ $t("invalid.code") }}</v-alert>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" :to="{ name: 'account.login' }" text>
        {{ $t("back.to.login") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        rounded
        :disabled="!valid"
        :loading="btnLoading"
        @click="onResetPassword"
        v-if="bValidRoute"
      >
        {{ $t("reset.password") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
import AuthMixin from "@/mixins/AuthMixin";
import { Auth } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class RestoreForm extends Mixins(AuthMixin) {
  sPassword = "";
  sConfirmationPassword = "";
  btnLoading = false;
  bValidRoute = false;

  @Prop(Boolean) readonly valid!: boolean;

  mounted() {
    this.$nextTick(this.onValidateResetPassword);
  }

  async onValidateResetPassword() {
    if (!this.code) {
      this.bValidRoute = false;
      return;
    }

    const obAuth = new Auth();
    obAuth.set("slug", this.code);
    const obResponse = await obAuth.checkResetCode(this.code);
    if (obResponse) {
      const obResponseData = obResponse.getData();

      if (obResponseData.message) {
        if (obResponseData.status) {
          this.flashSuccess(obResponseData.message);
        } else {
          this.flashError(obResponseData.message);
        }
      }

      this.bValidRoute = obResponseData.status;
    }
  }

  async onResetPassword() {
    if (!this.valid) {
      return;
    }

    this.btnLoading = true;

    const obAuth = new Auth();
    const obResponse = await obAuth.resetPassword(
      this.code,
      this.sPassword,
      this.sConfirmationPassword
    );

    this.btnLoading = false;

    if (obResponse) {
      const obResponseData = obResponse.getData();

      if (obResponseData.message) {
        if (obResponseData.status) {
          this.flashSuccess(obResponseData.message);
        } else {
          this.flashError(obResponseData.message);
        }
      }

      if (obResponseData.status) {
        await this.$router.push({ name: "account.login" });
      }
    }
  }

  get code() {
    return this.$route.params.slug;
  }
}
</script>
